import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import CountUp from 'react-countup';
import axios from 'axios';

const $ = require("jquery");
$.Datatable = require("datatables.net");

function Reportdaw() {
    let { id } = useParams();
    const [data, setData] = useState({
    todayTotal: 0,
    todayTotalone: 0,
    totalAmount: 0,
    totalAmountone:0,
   
  });
  const [loading, setLoading] = useState(true);
  const [loggedInUserId, setLoggedInUserId] = useState(null);
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

  const todayApi= ()=>{
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.get(baseUrl+`User/manualpayment/stats/${id}`, { headers })
      .then((res) => {
        console.warn(res.data);
        setData(res.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setLoading(false);
      });
  }



  const clearData = async (id, data) => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      };

      await axios.post(`${baseUrl}User/manualpayment/clear`, { id, data }, { headers });
      console.log('Data cleared successfully');
      todayApi();
    } catch (error) {
      console.error('There was an error clearing the data!', error);
    }
  };
  
  useEffect(() => {
    todayApi();
  }, [id]);


  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        const response = await axios.get(`${baseUrl}me`, { headers });
        setLoggedInUserId(response.data._id); 
      } catch (error) {
        console.error('Error fetching user ID', error);
      }
    };

    fetchUserId();
  }, []);




  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
       
      <h3 className='mt-3 text-danger'>DEPOSITE OVERVIEW</h3>
      <div className="row mt-5">
        <div className="col-xl-3 col-sm-6 grid-margin stretch-card" style={{ height: '11rem' }}>
          <div className="card">
            <div className="card-body text-light btn-outline-primary">
              <h4 className="text-muted font-weight-normal">TODAY DEPOSIT</h4>
              <div className="d-flex align-items-center align-self-start">
                <h3 className="pt-4 display-3 text-success">
                  ₹<CountUp start={0} delay={0.1} duration={0.2} end={data.todayTotal} />
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 grid-margin stretch-card" style={{ height: '11rem' }}>
          <div className="card">
            <div className="card-body text-light btn-outline-primary">
              <h4 className="text-muted font-weight-normal">TOTAL DEPOSIT</h4>
              <div className="d-flex align-items-center align-self-start">
                <h3 className="pt-4 display-3 text-success">
                  ₹<CountUp start={0} delay={0.1} duration={0.2} end={data.totalAmount} />
                </h3>
              </div>
            </div>
          </div>
        </div>


        <div className="col-xl-3 col-sm-6 grid-margin stretch-card" style={{ height: '11rem' }}>
          <div className="card">
            <div className="card-body text-light btn-outline-primary">
              <h4 className="text-muted font-weight-normal">TODAY WITHDRAWAL</h4>
              <div className="d-flex align-items-center align-self-start">
                <h3 className="pt-4 display-3 text-success">
                  ₹<CountUp start={0} delay={0.1} duration={0.2} end={data.todayTotalone} />
                </h3>
              </div>
            </div>
          </div>
        </div>


        <div className="col-xl-3 col-sm-6 grid-margin stretch-card" style={{ height: '11rem' }}>
          <div className="card">
            <div className="card-body text-light btn-outline-primary">
              <h4 className="text-muted font-weight-normal">TOTAL WITHDRAWAL</h4>
              <div className="d-flex align-items-center align-self-start">
                <h3 className="pt-4 display-3 text-success">
                  ₹<CountUp start={0} delay={0.1} duration={0.2} end={data.totalAmountone} />
                </h3>
              </div>
            </div>
          </div>
        </div>

        {loggedInUserId === '6671e1e306068d901342f969' && (
        <button onClick={() => clearData(id, {
          todayTotal: data.todayTotal,
          todayTotalone: data.todayTotalone,
          totalAmount: data.totalAmount,
          totalAmountone: data.totalAmountone
        })}>
          Clear Data
        </button>
      )}

      </div>
    </div>
  );
}

export default Reportdaw;
